import React from "react";
import ReactDOM from "react-dom";
import data from "./data";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import slugify from "slugify";

import "./styles.css";

const { posts } = data;

function PostHeader({ title, slug, date }) {
  return (
    <header id={slugify(title)}>
      <div className="copy">
        <span role="img" aria-label="Copy share link">
          🔗
        </span>
      </div>
      <h2>{title}</h2>
      <time>
        <small>{moment(date).format("MMMM Do YYYY, h:mm:ss a")}</small>
      </time>
    </header>
  );
}

function Post({ title, content, date, index }) {
  const slug = slugify(title);
  return (
    <article key={index}>
      <PostHeader title={title} slug={slug} date={date} />
      <ReactMarkdown source={content} />
    </article>
  );
}

function App() {
  return (
    <div className="App">
      <h1>
        Tone Row <sub>(Micro)</sub> Blog
      </h1>
      {Object.values(posts).map((post, index) => (
        <Post {...post} index={index} />
      ))}
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
